import React, { useEffect } from 'react'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import fireEvent from 'context/tracking/events'
import { MainBanner, MainContent } from 'mx/components/new'
import { ContentCarousel } from 'mx/components/new/content-carousel'
import { HeroBanner } from 'mx/components/new/partners/hero-banner'
import { Introduction } from 'mx/components/new/partners/introduction'
import Layout from 'mx/layout'
import { sanitizeUrl } from 'views/utils'

const PartnerDetails = (props: any) => {
  const {
    address,
    city,
    companyName,
    contentBlocks = [],
    description,
    heroImage,
    introduction,
    isStudio,
    logo,
    partnerCode,
  } = props.pageContext

  const [features, carousel, quote] = contentBlocks

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: companyName,
    })
  }, [])

  return (
    <Layout>
      <HeroBanner
        address={address.address}
        city={city}
        description={description.description}
        heroImage={heroImage}
        isStudio={isStudio}
        logo={logo}
        partnerCode={partnerCode}
      />
      <Introduction introduction={introduction} />
      <MainContent
        data={features}
        inline
        dark
        classname={tw`mt-20 lg:mt-32 !bg-brown-medium`}
      />
      {carousel && (
        <ContentCarousel
          data={carousel}
          projects
          classname={tw`mt-40 lg:mt-40`}
        />
      )}
      {quote && <MainBanner data={quote} classname={tw`mt-28 lg:mt-40`} />}
    </Layout>
  )
}

export default PartnerDetails

export const Head = (props: any) => {
  const { companyName, description, heroImage, publishingUrl } =
    props.pageContext
  const path = `/locations/${publishingUrl || sanitizeUrl(companyName)}`

  return (
    <SEO
      image={
        heroImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src
      }
      title={companyName}
      description={description.description}
      path={path}
    />
  )
}
