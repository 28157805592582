import React from 'react'

import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import tw from 'twin.macro'

import { getParagraph } from 'mx/components/new/utils'

interface IntroductionProps {
  introduction: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

export const Introduction = ({ introduction }: IntroductionProps) => {
  return (
    <section tw="flex flex-col w-full overflow-hidden md:mb-24">
      <div tw="h-full flex flex-col justify-between max-w-4xl w-full mx-auto pt-20 md:pt-40 mb-12 w-full md:mb-0 md:items-stretch">
        <div tw="mt-6 md:mt-0 pl-4 md:pl-0 flex flex-col gap-10 lg:gap-8">
          {getParagraph(
            introduction,
            tw`font-light text-gray-dark text-base md:text-lg leading-relaxed max-w-4xl`,
          )}
        </div>
      </div>
    </section>
  )
}
