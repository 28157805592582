import React from 'react'

import { navigate } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import tw, { css } from 'twin.macro'

import { ContentImage } from 'mx/components/new/utils'
import { WebsiteButton as Button } from 'mx/components/section-components'

interface HeroBannerProps {
  address: string
  city?: string
  description: string
  heroImage: ContentImage
  isStudio?: boolean
  logo: ContentImage
  partnerCode?: string
}

export const HeroBanner = ({
  address,
  city,
  description,
  heroImage,
  isStudio,
  logo,
  partnerCode,
}: HeroBannerProps) => {
  return (
    <div tw="bg-gray-light text-gray-dark">
      <div tw="flex w-full flex-col lg:flex-row max-md:flex-col-reverse">
        <div tw="px-12 py-20 lg:py-15 flex w-full text-balance flex-col justify-center gap-6 md:gap-4 max-sm:min-h-[400px] md:min-h-[600px] lg:min-h-fit sm:px-32 sm:py-20 lg:w-1/2 lg:px-20 xl:px-32">
          <img
            tw="w-52 h-auto lg:(w-72)"
            css={[!isStudio && tw`pb-6 md:pb-10`]}
            src={logo?.file?.url}
          />
          <div tw="flex flex-col gap-6 md:gap-10">
            {isStudio && (
              <p tw="text-xl font-light md:text-2xl uppercase">{city}</p>
            )}
            <h2 tw="text-base font-light md:text-lg md:max-w-2xl">
              {description}
            </h2>
            <p
              tw="text-lg font-light md:text-xl capitalize"
              dangerouslySetInnerHTML={{
                __html: address ?? '',
              }}
            />
            <Button
              color="solidDark"
              rounded
              onClick={() => {
                navigate(
                  `/welcome/new/project-info/your-details/${partnerCode}`,
                )
              }}
            >
              Book a Visit
            </Button>
          </div>
        </div>
        <div tw="w-full lg:w-1/2 flex justify-center items-center">
          <div
            tw="w-full relative"
            css={css`
              &:before {
                content: '';
                display: block;
                padding-bottom: 100%;
              }
            `}
          >
            <GatsbyImage
              alt={`${heroImage.description}`}
              title={`${heroImage.title}`}
              css={tw`absolute inset-0 h-full w-full`}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              image={
                heroImage.localFile?.childImageSharp
                  ?.gatsbyImageData as IGatsbyImageData
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
